import React from 'react'
import { AnswerContext } from '../App'
import questionData from "../data/data"

export default function TestPage({ handleOnSubmitClicked, handleOnContinueButtonClicked, handleOnPrintButtonClicked, handleOnResetClicked, disableForm}) {
  const { answers, setAnswers } = React.useContext(AnswerContext)

  function onCheckboxChanged(e, questionId, data) {
    setAnswers(questionId, data)
  }

  function renderQuestion(q) {
    return (
      <div key={q.name} className="form-item">
        <div className="form-item-field w-50"><span>{q.label.zh} <br/> {q.label.en}</span></div>
        <div className="form-item-checkbox w-25 text-center">
          <input type="checkbox" disabled={disableForm} className={answers[q.name].me ? "checked" : ""} checked={answers[q.name].me} onChange={e => onCheckboxChanged(e, q.name, {
            me: !answers[q.name].me,
            partner: answers[q.name].partner
          })}/>
        </div>
        <div className="form-item-checkbox w-25 text-center">
          <input type="checkbox" disabled={disableForm} className={answers[q.name].partner ? "checked" : ""} checked={answers[q.name].partner} onChange={e => onCheckboxChanged(e, q.name, {
            me: answers[q.name].me,
            partner: !answers[q.name].partner
          })}/>
        </div>
      </div>)
  }
  return (
    <div className="mx-3">
      <div className="form-item-header">
        <div className="w-50"><span className="pl-3">性格与特质 / Personal Characteristic</span></div>
        <div className="w-25 text-center">自己 / Me</div>
        <div className="w-25 text-center">伴侣 / Fiancé</div>
      </div>
      {questionData.map(q => renderQuestion(q))}
      <div className="text-center mt-5">
        {disableForm ? null :
          <button className="btn btn-primary" onClick={e => handleOnSubmitClicked(answers)}>完成 / DONE</button>}
        {disableForm ?
          <button className="btn btn-primary" onClick={e => handleOnPrintButtonClicked(answers)}>下载PDF / DOWNLOAD PDF</button> : null}
        {disableForm ?
          <button className="btn btn-secondary" onClick={handleOnContinueButtonClicked}>继续填写 / EDIT</button> : null}
        <button className="btn btn-secondary" onClick={e => handleOnResetClicked(answers)}>重新填写 / RESET</button>
      </div>
    </div>
  )
}
