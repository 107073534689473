import React from 'react';
import questionData from './data/data';
import './App.css';
import TestPage from "./components/TestPage"
import jsPDF from 'jspdf';
// import { KaiTi } from './fonts/KaiTi-normal.js'
import { unicodeMS } from './fonts/arial-unicode-ms-normal.js'
import * as ls from "local-storage"

const answersFromLS = ls.get("answers")
const answers = answersFromLS == null ? questionData.reduce((result, item) => {
  result[item.name] = {me: false, partner: false}
  return result
}, {}) : answersFromLS

export const AnswerContext = React.createContext({
  answers
})

function App() {

  const setAnswers = (name, ans) => {
    updateAnswers(prevState => {
      const { answers } = prevState
      const newAnswers = { ...answers, [name]: ans }
      return { ...prevState, answers: newAnswers }
    })
  }

  const answerState = {
    answers,
    setAnswers
  }

  const [state, updateAnswers] = React.useState(answerState)

  const [showResults, updateShowResults] = React.useState(false)

  function handleOnSubmitClicked(answers) {
    ls.set("answers", answers)
    updateShowResults(true)
  }

  function handleOnResetClicked() {
    ls.remove("answers")
    updateShowResults(false)
    updateAnswers(prevState => {
      const initAnswers = questionData.reduce((result, item) => {
        result[item.name] = { me: false, partner: false }
        return result
      }, {})
      return { ...prevState, answers: initAnswers }
    })
  }

  function handleOnContinueButtonClicked() {
    window.scrollTo(0,0)
    updateShowResults(false)
  }

  function handleOnPrintButtonClicked(answers1) {
    const doc = new jsPDF({
      orientation: 'p',
      unit: 'px',
      format: 'a4'
    })
    doc.addFileToVFS('unicodeMS.ttf', unicodeMS);
    doc.addFont('unicodeMS.ttf', 'unicodeMS', 'normal');
    doc.setTextColor(0, 0, 0);
    doc.setFillColor(135, 124, 45, 0)
    doc.setFont('unicodeMS');
    doc.setFontSize(14)
    let x = 30, y = 30;
    // doc.setFontType("bold");
    doc.text("今日的婚姻：你和我 - Part 1", x, y)
    //console.log(doc.getFontList())
    doc.setFontSize(12)
    y = y + 20

    const xMe = 250;
    const xPartner = 320
    doc.text("自己 / Me", xMe, y)
    doc.text("伴侣 / Fiancé", xPartner, y)
    y = y + 15
    questionData.forEach((q, index) => {
      if (index % 2 === 0) {
        doc.setFillColor(200, 200, 200)
        doc.rect(x - 10, y - 10, 408, 15, 'F')
      }

      doc.text(`${q.label.zh} / ${q.label.en}`, x, y)
      doc.text(answers1[q.name].me ? "(✔)" : "(   )", xMe + 15, y)
      doc.text(answers1[q.name].partner ? "(✔)" : "(   )", xPartner + 15, y)
      y = y + 15
      if(y > 600) {
        y = 30
        doc.addPage("a4", "p");
      }
    })

    if (/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(navigator.userAgent.toLowerCase())) {
      doc.save("marriagetoday-part1.pdf")
      // window.open(doc.output('bloburl', { filename: "marriagetoday-part1.pdf" }))
    } else {
      doc.save("marriagetoday-part1.pdf")
    }
  }

  return (
    <AnswerContext.Provider value={state}>
      <section className="container pb-5">
        <img src="/today_marriage_banner.png" className="w-100 banner-desktop" alt="今日的婚姻：你和我" title="今日的婚姻：你和我"/>
        <img src="/m_today_marriage_banner.png" className="w-100 banner-mobile" alt="今日的婚姻：你和我" title="今日的婚姻：你和我"/>
        <h2 className="text-center mt-1">PART 1</h2>
        <hr className="mb-3 mx-3" />
        <h3 className="px-3 my-1 mx-3">在进入婚姻时，我们都会对男人、女人、婚姻抱有一套看法。<br />这份问卷的目的是帮助你去观察一些会影响婚姻的思想、态度和期望。</h3>
        <h3 className="px-3 my-1 mb-3 mx-3">We all enter marriage with predetermined ideas about men, women, and marriage.<br />The
          purpose of this questionnaire is to help you discover some ideas, attitudes, and expectations
          that may influence your marriage.</h3>
        <h3 className="px-3 my-1 mx-3">以下是个人的性格与特质，请在与你相关的性格打勾，然后在与你的伴侣相关的性格打勾。</h3>
        <h3 className="px-3 my-1 mb-3 mx-3">The following is a list of personal characteristics. In the boxes provided, tick off (✔) the
          characteristics which frequently apply to you. Then tick off (✔) the right box for
          characteristics that apply to your fiancé.</h3>
        <div className="mx-3">
          <TestPage handleOnSubmitClicked={handleOnSubmitClicked}
                    handleOnContinueButtonClicked={handleOnContinueButtonClicked}
                    handleOnPrintButtonClicked={handleOnPrintButtonClicked}
                    handleOnResetClicked={handleOnResetClicked}
                    disableForm={showResults}/>
        </div>
      </section>
      <footer className="mb-3">
        <div className="text-center">
          <div className="mb-1">2020 © 天主教婚前准备课程 | Catholic (Mandarin) Marriage Preparation Course. All rights
            reserved.
          </div>
          <div>
            <small className="text-muted">Developed by William T. &amp; Stella L.</small>
          </div>
        </div>
      </footer>
    </AnswerContext.Provider>
  );
}

export default App
